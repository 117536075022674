// v2: added
import { useState } from 'react';
import Slider from 'react-slick';
import "./Past-Iterations.css"
import "./About.css"
import { VscChevronLeft, VscChevronRight } from "react-icons/vsc";
import { Fade } from 'react-awesome-reveal';

// import images
import img2017 from './2017logo.png';
import img2018 from './2018logo.png';
import img2019 from './2019logo.png';
import img2020 from './2020logo.png';
import img2022 from './2022logo.png';
import img2023 from './2023logo.png';
import img2024 from './2024logo.svg';

const images = [img2017, img2018, img2019, img2020, img2022, img2023, img2023, img2024];
const years = ["2017", "2018", "2019", "2020", "2022", "2023", "2023 Mini", "2024"];
const year_links = ['', 
                    'https://solajr.github.io/Hack-NYU-2018/', 
                    '', 
                    'https://solajr.github.io/HackNYU-2020/#home', 
                    'https://solajr.github.io/Hack-NYU-2022/', 
                    'https://hacknyu.org/2023/', 
                    'https://hacknyu.org/2023-mini-hackathon/',
                    'https://hacknyu.org/google-x-hacknyu/'
                  ];

function SampleNextArrow({onClick}) {
    return (
      <div className='arrow arrow-right' onClick={onClick}>
        <VscChevronRight/>
      </div>
    );
}

function SamplePrevArrow({onClick}) {
    return (
      <div className='arrow arrow-left' onClick={onClick}>
        <VscChevronLeft/>
      </div>
    );
}

function Iterations() {

    const [slideIndex, setSlideIndex] = useState(0);
  
    const settings = {
      dots: false,
      infinite: true,
      speed: 1000,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2500,
      beforeChange: (current, next)=>setSlideIndex(next),
      centerMode: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      appendDots: (dots) => (
        <div>
          <ul style={{ margin: "0px" }}> {dots} </ul>
        </div>
      ),
      customPaging: (current, next) => (
        <div className={current === slideIndex ? 'dot dot-active' : 'dot'}>
        </div>
      ),
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
          }
        }]
    };
  
    return (
      <Fade top duration={1000} distance="20px">
      <center>
        <div className="pi-container" id='past-iterations-page'>
            <div className="slider">

            <h2 className="section-title title">Past Iterations</h2>

            <p className="i-text-container">
                HackNYU has been around for quite a few years now. Take a look at all of our past hackathons!
            </p>

            <br></br>
            

            <div className="carousel-line2"></div>

            <Slider {...settings}>
            {
              images.map((img, index)=>(
                <div className={index === slideIndex ? "slide slide-active": "slide"} key={index}>
                  <img className="border"src={img} alt={years[index]} />

                  <br></br>

                    <div>
                        <button className={`year-label ${year_links[index] ? "has-link" : ""}`}
                                onClick={() => year_links[index] ? window.open(`${year_links[index]}`, "_blank") : null}
                        >
                            {years[index]}
                        </button>
                    </div>

                    {/* <button className="gallery-button">
                        <a href={gallery_links[index]}>Gallery</a>

                    </button> */}
                  
                </div>
              ))
              
            }   
            </Slider>
          </div>
      </div>
    </center>
    </Fade>
    );
  }

export default Iterations;

